import React from 'react';
import styled from 'styled-components';
import Faq from '../components/faq/Faq';
import bird2 from '../assets/images/birds/bird2.png';
import HomeLineup from '../components/home/HomeLineup';

const Page = styled.div`
  margin-top: 7.5vw;
  header {
    h1 {
      font-size: 3em;
      padding-bottom: 30px;
    }
  }
  .siteWrapper {
    grid-column: 1 / span 24;
    @media (min-width: 768px) {
      grid-column: 1 / span 24;
    }
    .intro-body {
      margin-bottom: 30px;
      .sub-title {
        font-size: 1.2rem;
      }
    }
  }
`;

const Bird = styled.div`
  position: absolute;
  left: -70px;
  top: 100px;

  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
  transform: translateY(-50%) rotate(0deg);
`;

export default function FaqPage() {
  return (
    <Page>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <div className="SiteContainer">
        <div className="siteWrapper" />
      </div>
      <HomeLineup />
    </Page>
  );
}
